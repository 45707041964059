import Immutable from 'immutable'
import { useMemo } from 'react'
import AthleticCropTop from './Products/AthleticCropTop'
import Beanie from './Products/Beanie'
import BedPillowSham from './Products/BedPillowSham'
import BedPillowSleeve from './Products/BedPillowSleeve'
import Blanket from './Products/Blanket'
import BodyconDress from './Products/BodyconDress'
import BoxerBriefs from './Products/BoxerBriefs'
import Boyshorts from './Products/Boyshorts'
import Canvas from './Products/Canvas'
import CheekyBriefs from './Products/CheekyBriefs'
import DeskMat from './Products/DeskMat'
import DrawstringBag from './Products/DrawstringBag'
import DuvetCover from './Products/DuvetCover'
import FittedPoloShirt from './Products/FittedPoloShirt'
import FittedSkirt from './Products/FittedSkirt'
import FlareDress from './Products/FlareDress'
import FlareSkirt from './Products/FlareSkirt'
import Headband from './Products/Headband'
import Leggings from './Products/Leggings'
import MakeupBag from './Products/MakeupBag'
import MarketTote from './Products/MarketTote'
import PencilCase from './Products/PencilCase'
import PermanentSticker from './Products/PermanentSticker'
import Pillow18 from './Products/Pillow18'
import Pillow20x14 from './Products/Pillow20x14'
import Pillow22 from './Products/Pillow22'
import Pillow24x12 from './Products/Pillow24x12'
import Pillow26x20 from './Products/Pillow26x20'
import ScoopBralette from './Products/ScoopBralette'
import StainlessSteelTumbler from './Products/StainlessSteelTumbler'
import TeaTowel from './Products/TeaTowel'
import ToteBag from './Products/ToteBag'
import UnisexJoggers from './Products/UnisexJoggers'
import UnisexPoloShirt from './Products/UnisexPoloShirt'
import VeganLeatherBackpack from './Products/VeganLeatherBackpack'
import VeganLeatherCrossbodyPurse from './Products/VeganLeatherCrossbodyPurse'
import VeganLeatherMakeupBag from './Products/VeganLeatherMakeupBag'
import VeganLeatherToteBag from './Products/VeganLeatherToteBag'
import YogaShorts from './Products/YogaShorts'
import ZipperCarryAll from './Products/ZipperCarryAll'
import OrigamiTote from './Products/OrigamiTote'
import DayTote from './Products/DayTote'
import StationeryCard from './Products/StationeryCard'
import Notebook from './Products/Notebook'
import SpiralNotebook from './Products/SpiralNotebook'
import Capris from './Products/Capris'
import YogaLeggings from './Products/YogaLeggings'
import YogaCapris from './Products/YogaCapris'
import Shorts from './Products/Shorts'
import MiniShorts from './Products/MiniShorts'
import WrapSkirt from './Products/WrapSkirt'
import BabyLeggings from './Products/BabyLeggings'
import YouthLeggings from './Products/YouthLeggings'
import StainlessSteelWaterBottle from './Products/StainlessSteelWaterBottle'
import KimonoRobe from './Products/KimonoRobe'
import Peignoir from './Products/Peignoir'
import LooseTankTop from './Products/LooseTankTop'
import FittedTankTop from './Products/FittedTankTop'
import HolidayStocking from './Products/HolidayStocking'
import Coaster from './Products/Coaster'

export interface ProductModelProps {
    viewerId: string,
    options: Immutable.Map<string, string>,
    variant: string,
}

type Props = {
    viewerId: string,
    productId: number,
    options: Immutable.Map<string, string>,
    variant: string,
}

export default function ModelViewerProduct(props: Props) {
    const productModelProps = useMemo(() => {
        return {
            viewerId: props.viewerId,
            options: props.options,
            variant: props.variant,
        }
    }, [props.viewerId, props.options, props.variant])

    switch (props.productId) {
        case 7: return <PencilCase {...productModelProps} />
        case 8: return <Pillow18 {...productModelProps} />
        case 10: return <Pillow22 {...productModelProps} />
        case 12: return <Leggings {...productModelProps} />
        case 16: return <Capris {...productModelProps} />
        case 17: return <FittedSkirt {...productModelProps} />
        case 18: return <ToteBag {...productModelProps} />
        case 20: return <FlareSkirt {...productModelProps} />
        case 27: return <BabyLeggings {...productModelProps} />
        case 31: return <Pillow24x12 {...productModelProps} />
        case 32: return <Pillow20x14 {...productModelProps} />
        case 33: return <Pillow26x20 {...productModelProps} />
        case 35: return <BodyconDress {...productModelProps} />
        case 36: return <FlareDress {...productModelProps} />
        case 39: return <Canvas {...productModelProps} />
        case 42: return <Beanie {...productModelProps} />
        case 43: return <KimonoRobe {...productModelProps} />
        case 45: return <MakeupBag {...productModelProps} />
        case 46: return <YogaLeggings {...productModelProps} />
        case 47: return <Shorts {...productModelProps} />
        case 58: return <YogaCapris {...productModelProps} />
        case 59: return <Headband {...productModelProps} />
        case 60: return <YouthLeggings {...productModelProps} />
        case 61: return <AthleticCropTop {...productModelProps} />
        case 62: return <MiniShorts {...productModelProps} />
        case 63: return <DayTote {...productModelProps} />
        case 64: return <LooseTankTop {...productModelProps} />
        case 65: return <FittedTankTop {...productModelProps} />
        case 66: return <Notebook {...productModelProps} />
        case 67: return <OrigamiTote {...productModelProps} />
        case 69: return <StationeryCard {...productModelProps} />
        case 70: return <Peignoir {...productModelProps} />
        case 71: return <SpiralNotebook {...productModelProps} />
        case 73: return <ZipperCarryAll {...productModelProps} />
        case 77: return <WrapSkirt {...productModelProps} /> 
        case 78: return <CheekyBriefs {...productModelProps} />
        case 79: return <Boyshorts {...productModelProps} />
        case 80: return <BoxerBriefs {...productModelProps} />
        case 86: return <DuvetCover {...productModelProps} />
        case 89: return <BedPillowSham {...productModelProps} />
        case 90: return <BedPillowSleeve {...productModelProps} />
        case 94: return <TeaTowel {...productModelProps} />
        case 95: return <MarketTote {...productModelProps} />
        case 98: return <ScoopBralette {...productModelProps} />
        case 99: return <HolidayStocking {...productModelProps} />
        case 117: return <Blanket {...productModelProps} />
        case 118: return <VeganLeatherCrossbodyPurse {...productModelProps} />
        case 119: return <VeganLeatherMakeupBag {...productModelProps} />
        case 120: return <VeganLeatherToteBag {...productModelProps} />
        case 121: return <YogaShorts {...productModelProps} />
        case 122: return <PermanentSticker {...productModelProps} />
        case 123: return <VeganLeatherBackpack {...productModelProps} />
        case 124: return <DrawstringBag {...productModelProps} />
        case 125: return <StainlessSteelTumbler {...productModelProps} />
        case 126: return <UnisexPoloShirt {...productModelProps} />
        case 127: return <FittedPoloShirt {...productModelProps} />
        case 128: return <UnisexJoggers {...productModelProps} />
        case 129: return <DeskMat {...productModelProps} />
        case 130: return <StainlessSteelWaterBottle {...productModelProps} />
        case 131: return <Coaster {...productModelProps} />
    }

    return null
}