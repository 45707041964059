import { useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function HolidayStocking(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/holiday-stocking.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Handle Loop Color
    const option_loopColor = props.options.get('loop-color') || "black";
    useEffect(() => {
        let mat = FindMaterial("loop", elements)
        if (mat) {
            switch (option_loopColor) {
                case 'black':
                    mat.color.setHex(0x080808)
                    break;
                case 'white':
                    mat.color.setHex(0xffffff)
                    break;
            }
        }
    }, [elements, option_loopColor])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return

        let mat = FindMaterial('outside', elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}